import { email, helpers, minLength, required } from '@vuelidate/validators'
import type { IValidatorOptions } from '@/modules/core/types'

interface IValidatorOptionsMin extends IValidatorOptions {
  min: number
}

interface IValidatorOptionsSize extends IValidatorOptions {
  size: number
}

export default {
  required: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Campo obrigatório.'

    return helpers.withMessage(message, required)
  },

  min: (
    validatorOptions: IValidatorOptionsMin = {} as IValidatorOptionsMin,
  ) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : `Informe no mínimo ${validatorOptions.min} caracteres.`

    return helpers.withMessage(message, minLength(validatorOptions.min))
  },

  email: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Email inválido'

    return helpers.withMessage(message, email)
  },

  fullname: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Nome completo inválido.'

    return helpers.withMessage(message, (fullname: string) => {
      const [name, surname] = fullname.split(' ')

      return !!(name && surname)
    })
  },

  size: (
    validatorOptions: IValidatorOptionsSize = {} as IValidatorOptionsSize,
  ) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : `Este campo deve conter ${validatorOptions.size} caracteres`

    return helpers.withMessage(message, minLength(validatorOptions.size))
  },

  alpha: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Este campo deverá conter apenas caracteres alfabéticos'

    return helpers.withMessage(message, (v: string) => /^[a-zA-Z\s]*$/.test(v))
  },

  taxId: (validatorOptions: IValidatorOptions = {}) => {
    const isCPFValid = (value: string) => {
      const cleanCPF = value.replace(/[^\d]/g, '') // Remove caracteres não numéricos

      if (cleanCPF.length !== 11)
        return false

      // Verifica se todos os dígitos são iguais, o que invalida o CPF
      if (/^(\d)\1+$/.test(cleanCPF))
        return false

      // Calcula os dígitos verificadores
      let sum = 0

      for (let i = 0; i < 9; i++)
        sum += Number.parseInt(cleanCPF.charAt(i)) * (10 - i)

      let remainder = 11 - (sum % 11)

      remainder = remainder === 10 || remainder === 11 ? 0 : remainder

      if (remainder !== Number.parseInt(cleanCPF.charAt(9)))
        return false

      sum = 0

      for (let i = 0; i < 10; i++)
        sum += Number.parseInt(cleanCPF.charAt(i)) * (11 - i)

      remainder = 11 - (sum % 11)

      remainder = remainder === 10 || remainder === 11 ? 0 : remainder

      if (remainder !== Number.parseInt(cleanCPF.charAt(10)))
        return false

      return true
    }

    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Digite um CPF válido'

    return helpers.withMessage(message, isCPFValid)
  },

  birthdate: (validatorOptions: IValidatorOptions = {}) => {
    const isBirthDate = (value: string) => {
      const [day, month, year] = value.split('/').map(Number)

      if (year < 1900 || year > new Date().getFullYear())
        return false

      if (month < 1 || month > 12)
        return false

      if (day < 1 || day > 31)
        return false

      if (month === 4 || month === 6 || month === 9 || month === 11) {
        if (day < 1 || day > 30)
          return false
      }

      if (month === 2) {
        const maxDays = year % 4 === 0 ? 29 : 28

        if (day < 1 || day > maxDays)
          return false
      }

      return true
    }

    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Data de nascimento inválida'

    return helpers.withMessage(message, isBirthDate)
  },

  custom: () => {
    return helpers.withMessage('', () => false)
  },
}
